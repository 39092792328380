// ▼トップへ戻るボタン
const showArrowBtn = function () {
  document.addEventListener("DOMContentLoaded", function () {
    const arrowBtn = document.getElementById("js-arrow-btn");
    if (!arrowBtn) {
      return false;
    }
    document.addEventListener("scroll", function () {
      arrowBtn.classList.toggle("is-fadein", window.pageYOffset > 780);
    });

    arrowBtn.addEventListener("click", function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  });
};
showArrowBtn();
