$(() => {
  /*
    1: 1 2 3 ... 20
    2: 1 2 3 4 ... 20
    3: 1 2 3 4 5 ... 20
    4: 1 2 3 4 5 6 ... 20
    5: 1 ... 4 5 6 7 ... 20
    16: 1 ... 15 16 17 18 19 20
    17: 1 ... 16 17 18 19 20
    18: 1 ... 17 18 19 20
    19: 1 ... 18 19 20
    20: 1 ... 18 19 20
  */

  const pagination = $(".pagination-list-item:not(:first-child):not(:last-child)");
  const current = pagination.index(pagination.filter(".current")) + 1;
  const max = pagination.length;

  const skip = lis => {
    lis
      .first().replaceWith('<li class="pagination-list-item-dot"><a>・・・</a></li>').end()
      .slice(1).remove();
  };

  if(current === 1){
    skip(pagination.slice(3, -1));

  }else if(current <= 4){
    skip(pagination.slice(current+2, -1));

  }else if(current === max){
    skip(pagination.slice(1, -3));

  }else if(current >= max - 4){
    skip(pagination.slice(1, current-2));

  }else{
    skip(pagination.slice(1, current-2));
    skip(pagination.slice(current+2, -1));
  }
});
