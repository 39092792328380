document.addEventListener(
  "DOMContentLoaded",
  function () {
    // タブに対してクリックイベントを適用
    const tabs = document.getElementsByClassName("tab");
    for (let i = 0; i < tabs.length; i++) {
      tabs[i].addEventListener("click", tabSwitch, false);
    }

    // タブをクリックすると実行する関数
    function tabSwitch() {
      // タブのclassの値を変更
      document
        .getElementsByClassName("is-active")[0]
        .classList.remove("is-active");
      this.classList.add("is-active");
    }
  },
  false
);
