// ▼ドロワーメニューの開閉処理
window.addEventListener("DOMContentLoaded", function () {
  const drawerBtnActive = function () {
    const body = document.querySelector("body");
    const drawerBtn = document.getElementById("js-drawer-button");
    const drawerBgWhite = document.getElementById("js-drawer-bg-white");
    const drawerNav = document.getElementById("js-drawer-nav");
    const drawerNavLink = document.querySelectorAll(".js-navHeaderLink");
    const drawerNavLinkArr = Array.prototype.slice.call(drawerNavLink);
    if (!drawerBtn) {
      return false;
    }
    // ▼ドロワーボタンや背景を押して表示・非表示を切り替える処理
    drawerBtn.addEventListener("click", checkExpanded);
    drawerBgWhite.addEventListener("click", checkExpanded);

    function checkExpanded() {
      body.classList.toggle("is-drawerActive");
      drawerNav.classList.toggle("is-drawerActive");
      drawerBgWhite.classList.toggle("is-drawerActive");
      if (drawerBtn.getAttribute("aria-expanded") === "false") {
        drawerBtn.setAttribute("aria-expanded", true);
      } else {
        drawerBtn.setAttribute("aria-expanded", false);
      }
    }

    // // ▼トップページ ドロワーのメニューを開閉させる処理
    drawerNavLinkArr.forEach(function(drawerNavLinks)  {
      drawerNavLinks.addEventListener("click", function () {
        body.classList.toggle("is-drawerActive");
        drawerNav.classList.toggle("is-drawerActive");
        drawerBgWhite.classList.toggle("is-drawerActive");
        if (drawerBtn.getAttribute("aria-expanded") === "false") {
          drawerBtn.setAttribute("aria-expanded", true);
        } else {
          drawerBtn.setAttribute("aria-expanded", false);
        }
      });
    });
  };
  drawerBtnActive();
});
